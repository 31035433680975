import {
  PASSWORD_MIN_LENGTH,
  NEW_PASSWORD_MIN_LENGTH,
  language,
} from "@chef/constants";

export const intl = (
  {
    no: {
      EMAIL_AND_PASSWORD: "E-post og passord",
      ONE_TIME_CODE: "Engangskode",
      EMAIL: "E-post",
      PASSWORD: "Passord",
      TELEPHONE: "Mobilnummer",
      LOG_IN: "Logg inn",
      SEND_CODE: "Send kode",
      REMEMBER_ME: "Husk meg",
      FORGOT_EMAIL_OR_PASSWORD: "Glemt e-post eller passord?",
      CANCEL: "Avbryt",
      GENERAL_ERROR:
        "Noe gikk galt. Sjekk at du har skrevet inn opplysningene riktig og prøv igjen.",
      EMAIL_IS_REQUIRED: "E-post må fylles ut",
      EMAIL_IS_NOT_VALID: "E-post er ikke gyldig",
      PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Passord må være minst ${PASSWORD_MIN_LENGTH} tegn`,
      NEW_PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Passord må være minst ${NEW_PASSWORD_MIN_LENGTH} tegn`,
      PASSWORD_IS_REQUIRED: "Passord må fylles ut",
      TELEPHONE_MAX_LENGTH: 8,
      TELEPHONE_HINT: "F.eks. 12345678",
      TELEPHONE_REGEX: /^[0-9]{8}$/,
      TELEPHONE_MUST_BE_NUMERIC: "Mobilnummer må bestå av tall",
      TELPHONE_IS_REQUIRED: "Mobilnummer må fylles ut",
      NEW_PASSWORD: "Nytt passord",
      CONFIRM_PASSWORD: "Bekreft passord",
      SAVE_PASSWORD: "Lagre passord",
      CREATE_NEW_PASSWORD: "Lag nytt passord",
      FORGOTTEN_PASSWORD_INFO:
        "Skriv inn den e-postadressen som du brukte for å lage din konto, så sender vi deg en lenke for å tilbakestille passordet. Om e-posten ikke kommer fram, sjekk at den ikke havnet i søppelposten din.",
      WRITE_YOUR_NEW_PASSWORD: `Skriv inn ditt nye passord i feltene under. Passordet må inneholde minst ${NEW_PASSWORD_MIN_LENGTH} tegn.`,
      RECOVER_PASSWORD_EMAIL_SENT:
        "Vi har sendt deg en e-post med en lenke for å endre passordet ditt. Det kan ta noen minutter før du får denne e-posten. Sjekk også søppelposten.",
      PASSWORDS_MUST_MATCH: "Passordene må være like",
    },
    se: {
      EMAIL_AND_PASSWORD: "E-post och lösenord",
      ONE_TIME_CODE: "Engångskod",
      EMAIL: "E-post",
      PASSWORD: "Lösenord",
      TELEPHONE: "Mobilnummer",
      LOG_IN: "Logga in",
      SEND_CODE: "Skicka kod",
      REMEMBER_ME: "Kom ihåg mig",
      FORGOT_EMAIL_OR_PASSWORD: "Glömt e-post eller lösenord?",
      CANCEL: "Avbryt",
      GENERAL_ERROR:
        "Något gick fel. Kontrollera att du har angett rätt uppgifter och försök igen.",
      EMAIL_IS_REQUIRED: "E-post måste fyllas i",
      EMAIL_IS_NOT_VALID: "E-post är inte giltig",
      PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Lösenord måste vara minst ${PASSWORD_MIN_LENGTH} tecken`,
      NEW_PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Lösenord måste vara minst ${NEW_PASSWORD_MIN_LENGTH} tecken`,
      TELEPHONE_MAX_LENGTH: 10,
      TELEPHONE_HINT: "T.ex. 0701234567",
      TELEPHONE_REGEX: /^[0-9]{10}$/,
      TELEPHONE_MUST_BE_NUMERIC: "Mobilnummer måste bestå av siffror",
      TELPHONE_IS_REQUIRED: "Mobilnummer måste fyllas i",
      NEW_PASSWORD: "Nytt lösenord",
      CONFIRM_PASSWORD: "Bekräfta lösenord",
      SAVE_PASSWORD: "Spara lösenord",
      CREATE_NEW_PASSWORD: "Skapa nytt lösenord",
      FORGOTTEN_PASSWORD_INFO:
        "Ange den e-postadress som du använde för att skapa ditt konto, så skickar vi dig en länk för att återställa lösenordet. Om mailet inte verkar komma fram, kontrollera att det inte hamnat bland din skräppost.",
      WRITE_YOUR_NEW_PASSWORD: `Skriv in ditt nya lösenord i fälten nedan. Lösenordet måste innehålla minst ${NEW_PASSWORD_MIN_LENGTH} tecken.`,
      RECOVER_PASSWORD_EMAIL_SENT:
        "Vi har skickat dig ett e-postmeddelande med en länk för att återställa ditt lösenord. Det kan ta några minuter innan du får detta e-postmeddelande. Kontrollera även din skräppost.",
      PASSWORDS_MUST_MATCH: "Lösenorden måste matcha",
    },
    dk: {
      EMAIL_AND_PASSWORD: "E-mail og password",
      ONE_TIME_CODE: "Engangskode",
      EMAIL: "E-mail",
      PASSWORD: "Password",
      TELEPHONE: "Mobilnummer",
      LOG_IN: "Log ind",
      SEND_CODE: "Send kode",
      REMEMBER_ME: "Husk mig",
      FORGOT_EMAIL_OR_PASSWORD: "Glemt e-mail eller password?",
      CANCEL: "Annuller",
      GENERAL_ERROR:
        "Noget gik galt. Tjek at du har indtastet oplysningerne korrekt, og prøv igen.",
      EMAIL_IS_REQUIRED: "E-mail skal udfyldes",
      EMAIL_IS_NOT_VALID: "E-mail er ikke gyldig",
      PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Password skal være mindst ${PASSWORD_MIN_LENGTH} tegn`,
      NEW_PASSWORD_MUST_BE_MINIMUM_CHARACTERS: `Password skal være mindst ${NEW_PASSWORD_MIN_LENGTH} tegn`,
      TELEPHONE_MAX_LENGTH: 8,
      TELEPHONE_HINT: "F.eks. 12345678",
      TELEPHONE_REGEX: /^[0-9]{8}$/,
      TELEPHONE_MUST_BE_NUMERIC: "Mobilnummer skal bestå af tal",
      TELPHONE_IS_REQUIRED: "Mobilnummer skal udfyldes",
      NEW_PASSWORD: "Nyt password",
      CONFIRM_PASSWORD: "Bekræft password",
      SAVE_PASSWORD: "Gem password",
      CREATE_NEW_PASSWORD: "Nulstille password",
      FORGOTTEN_PASSWORD_INFO:
        "Hvis du har glemt dit password, kan du nulstille det ved at indtaste din e-mail og trykke 'Send'",
      WRITE_YOUR_NEW_PASSWORD: `Skriv dit nye password i felterne nedenfor. Passwordet skal indeholde mindst ${NEW_PASSWORD_MIN_LENGTH} tegn.`,
      RECOVER_PASSWORD_EMAIL_SENT:
        "Vi har sendt dig en e-mail med et link til at nulstille dit password. Det kan tage nogle minutter, før du modtager denne e-mail. Tjek også din spam-mappe.",
      PASSWORDS_MUST_MATCH: "Passwords skal matche",
    },
  } as const
)[language];
