import { BRAND_NAME, language } from "@chef/constants";

export const intl = (
  {
    no: {
      NO_ACCOUNT: "Ingen konto?",
      ORDER_MEALBOX_AND_CREATE_ACCOUNT_HERE:
        "Bestill matkasse og lag konto her",
      LOG_IN: "Logg inn",
    },
    se: {
      NO_ACCOUNT: "Inget konto?",
      ORDER_MEALBOX_AND_CREATE_ACCOUNT_HERE:
        "Beställ matkasse och skapa konto här",
      LOG_IN: "Logga in",
    },
    dk: {
      NO_ACCOUNT: "Ingen konto?",
      ORDER_MEALBOX_AND_CREATE_ACCOUNT_HERE:
        "Bestil måltidskasse og opret konto her",
      LOG_IN: "Log ind ",
    },
  } as const
)[language];

export const brand = (
  {
    AMK: {
      LOG_IN_TO_BRAND: "Logg inn på Adams Matkasse",
    },
    GL: {
      LOG_IN_TO_BRAND: "Logg inn på Godtlevert",
    },
    LMK: {
      LOG_IN_TO_BRAND: "Logga in på Linas Matkasse",
    },
    RN: {
      LOG_IN_TO_BRAND: "Log ind på RetNemt",
    },
  } as const
)[BRAND_NAME];
