import Link from "next/link";
import { GetStaticProps, InferGetStaticPropsType } from "next";

import { AppShell } from "@chef/feature-app-shell";
import { BRAND_PATHS } from "@chef/constants";
import {
  ContentComponent,
  ISection,
  SanityQuery,
  getSectionData,
  useSectionData,
} from "@chef/feature-sanity";

import { LoginForm } from "../components";
import { brand, intl } from "./LoginPage.Intl";

export const LoginPage = ({
  sideContent: _sideContent,
}: InferGetStaticPropsType<typeof LoginPageGetStaticProps>) => {
  const [sideContent] = useSectionData(_sideContent);

  return (
    <AppShell title={intl.LOG_IN}>
      <AppShell.Meta />
      <AppShell.Header />
      <AppShell.Main grid gradient>
        <div className="flex flex-col col-span-4 gap-6">
          <h1 className="w-2/3 mb-4 md:w-auto">
            <strong>{brand.LOG_IN_TO_BRAND}</strong>
          </h1>

          <LoginForm />

          <div className="flex flex-col gap-2 p-4 border rounded bg-grey-3">
            <h2 className="text-lg md:text-lg">
              <strong>{intl.NO_ACCOUNT}</strong>
            </h2>
            <p>
              <Link href={BRAND_PATHS.SIGNUP_HREF} className="underline">
                {intl.ORDER_MEALBOX_AND_CREATE_ACCOUNT_HERE}
              </Link>
            </p>
          </div>
        </div>
        <div className="relative hidden h-screen col-span-4 overflow-y-hidden md:block xl:col-span-8">
          {sideContent && <ContentComponent block={sideContent.content} />}
        </div>
      </AppShell.Main>
      <AppShell.Footer />
    </AppShell>
  );
};

export const LoginPageGetStaticProps: GetStaticProps<
  {
    draftMode: boolean;
    sideContent: ISection;
  },
  SanityQuery
> = async ({ draftMode = false }) => {
  const sideContent = await getSectionData("login_dialog", {
    preview: draftMode,
  });

  return {
    props: {
      draftMode,
      sideContent,
    },
    revalidate: 600,
  };
};
