import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { Button, Input, Snackbar } from "@chef/components";
import { useExtendedForm } from "@chef/hooks";
import {
  useCreateTelephoneTokenMutation,
  useLoginMutation,
  useRecoverPasswordCallbackMutation,
  useRecoverPasswordMutation,
  useValidateTelephoneTokenMutation,
} from "@chef/state-management";
import {
  BRAND_LOGGED_IN_START_PAGE,
  NEW_PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "@chef/constants";
import { intl } from "./LoginForm.Intl";

interface LoginFormProps {
  recovery?: boolean;
  initialEmail?: string;
  initialTelephone?: string;
  initialLoginMethod?: "email" | "telephone";
}

type IFormInput =
  | {
      method: "email";
      email: string;
      password: string;
    }
  | {
      method: "telephone";
      telephone: string;
    }
  | {
      method: "otp";
      sessionId: string;
      token: string;
    }
  | {
      method: "forgot-password-email";
      email: string;
    }
  | {
      method: "forgot-password-token";
      email: string;
      token: string;
      newPassword: string;
      confirmPassword: string;
    };

const RECOVERY_COOLDOWN = 60; // 1 minute

const schema = yup.object({
  method: yup
    .string()
    .oneOf([
      "email",
      "telephone",
      "otp",
      "forgot-password-email",
      "forgot-password-token",
    ])
    .required(),
  email: yup.string().when("method", {
    is: (value: string) =>
      value === "email" || value === "forgot-password-email",
    then: yup
      .string()
      .required(intl.EMAIL_IS_REQUIRED)
      .email(intl.EMAIL_IS_NOT_VALID),
  }),
  password: yup.string().when("method", {
    is: "email",
    then: yup
      .string()
      .required(intl.PASSWORD_IS_REQUIRED)
      .min(PASSWORD_MIN_LENGTH, intl.PASSWORD_MUST_BE_MINIMUM_CHARACTERS),
  }),
  telephone: yup.string().when("method", {
    is: "telephone",
    then: yup
      .string()
      .required(intl.TELPHONE_IS_REQUIRED)
      .matches(intl.TELEPHONE_REGEX, intl.TELEPHONE_MUST_BE_NUMERIC),
  }),
  newPassword: yup.string().when("method", {
    is: "forgot-password-token",
    then: yup
      .string()
      .min(
        NEW_PASSWORD_MIN_LENGTH,
        intl.NEW_PASSWORD_MUST_BE_MINIMUM_CHARACTERS,
      )
      .required(),
  }),
  confirmPassword: yup.string().when("method", {
    is: "forgot-password-token",
    then: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], intl.PASSWORDS_MUST_MATCH),
  }),
});

const push = (path: string) => {
  const url = new URL(path, window.location.origin);

  window.location.href = url.toString();
};

export const LoginForm = ({
  recovery,
  initialTelephone,
  initialEmail,
  initialLoginMethod = "email",
}: LoginFormProps) => {
  const router = useRouter();
  const [login, { isError: isLoginError, reset: resetLogin }] =
    useLoginMutation();

  const [
    createTelephoneToken,
    {
      fulfilledTimeStamp: createTelephoneTokenTimestamp,
      isError: isCreateTelephoneTokenError,
    },
  ] = useCreateTelephoneTokenMutation();

  const [validateTelephoneToken, { isError: isValidateTelephoneTokenError }] =
    useValidateTelephoneTokenMutation();

  const [
    recoverPassword,
    {
      fulfilledTimeStamp: recoverPasswordTimestamp,
      isError: isRecoverPasswordError,
      isSuccess: isRecoverPasswordSuccess,
    },
  ] = useRecoverPasswordMutation();

  const [recoverPasswordCallback, { isError: isRecoverPasswordCallbackError }] =
    useRecoverPasswordCallbackMutation();

  const { register, handleSubmit, formState, formValues, setValue } =
    useExtendedForm<IFormInput>({
      defaultValues: {
        email: initialEmail || "",
        password: "",
        method: recovery ? "forgot-password-token" : initialLoginMethod,
        telephone: initialTelephone || "",
      },
      resolver: yupResolver(schema),
      mode: "all",
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetLogin, [formValues.method]);

  const onSubmit = async (data: IFormInput) => {
    const from = router.query["from"] as string | undefined;

    if (data.method === "email") {
      await login({
        email: data.email,
        password: data.password,
      }).unwrap();
      push(from || BRAND_LOGGED_IN_START_PAGE);
    }

    if (data.method === "telephone") {
      const d = await createTelephoneToken({
        telephone: data.telephone,
      }).unwrap();

      setValue("sessionId", d.sessionId);
      setValue("method", "otp");
    }

    if (data.method === "otp" && data.sessionId && data.token) {
      await validateTelephoneToken({
        sessionId: data.sessionId,
        token: data.token,
      }).unwrap();

      push(from || BRAND_LOGGED_IN_START_PAGE);
    }

    if (data.method === "forgot-password-email") {
      await recoverPassword({ Email: data.email }).unwrap();
    }

    if (data.method === "forgot-password-token") {
      await recoverPasswordCallback({
        password: data.newPassword,
        token: router.query.token as string,
      }).unwrap();

      setValue("method", "email");
      setValue("email", data.email);
      setValue("password", data.newPassword);
    }
  };

  const isEmail = formValues.method === "email";
  const isTelephone =
    formValues.method === "telephone" || formValues.method === "otp";

  const showMenu = isEmail || isTelephone;

  const isGeneralError =
    isLoginError ||
    isCreateTelephoneTokenError ||
    isValidateTelephoneTokenError ||
    isRecoverPasswordError ||
    isRecoverPasswordCallbackError;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 rounded bg-grey-3"
    >
      {showMenu && (
        <div className="flex">
          <label
            className={clsx(
              "p-4 border-b-2 border-solid grow cursor-pointer text-center rounded-tl",
              isEmail
                ? "bg-grey-2 border-primary"
                : "bg-grey-3 border-grey-1/50 text-grey-1",
            )}
          >
            <strong>{intl.EMAIL_AND_PASSWORD}</strong>
            <input
              {...register("method")}
              type="radio"
              value="email"
              id="field-email"
              className="sr-only"
            />
          </label>

          <label
            className={clsx(
              "p-4 border-b-2 border-solid grow cursor-pointer text-center rounded-tr",
              isTelephone
                ? "bg-grey-2 border-primary"
                : "bg-grey-3 border-grey-1/50 text-grey-1",
            )}
          >
            <strong>{intl.ONE_TIME_CODE}</strong>
            <input
              {...register("method")}
              type="radio"
              value="telephone"
              id="field-telephone"
              className="sr-only"
            />
          </label>
        </div>
      )}

      {isEmail && (
        <div className="flex flex-col gap-4 p-4">
          <Input
            id="email"
            {...register("email")}
            placeholder={intl.EMAIL}
            type="email"
          />

          <Input
            id="password"
            {...register("password")}
            placeholder={intl.PASSWORD}
            type="password"
          />

          <div className="flex justify-between">
            <div aria-hidden="true" />
            <label className="text-sm underline hover:cursor-pointer">
              {intl.FORGOT_EMAIL_OR_PASSWORD}
              <input
                {...register("method")}
                type="radio"
                value="forgot-password-email"
                id="field-forgot-password-email"
                className="sr-only"
              />
            </label>
          </div>

          <Button
            id="submit-button"
            primary
            loading={formState.isSubmitting}
            type="submit"
          >
            {intl.LOG_IN}
          </Button>
        </div>
      )}

      {isTelephone && (
        <div className="flex flex-col gap-4 p-4">
          <Input
            id="telephone"
            {...register("telephone")}
            type="tel"
            placeholder={intl.TELEPHONE}
            helperText={intl.TELEPHONE_HINT}
            maxLength={intl.TELEPHONE_MAX_LENGTH}
          />

          <Button primary loading={formState.isSubmitting}>
            {intl.SEND_CODE}
          </Button>
        </div>
      )}

      {formValues.method === "otp" && (
        <div className="flex flex-col gap-4 p-4">
          <input {...register("sessionId")} type="hidden" />

          <Input
            id="token"
            {...register("token")}
            placeholder={intl.ONE_TIME_CODE}
            type="text"
            maxLength={6}
            autoFocus
          />

          <Button primary loading={formState.isSubmitting}>
            {intl.LOG_IN}
          </Button>
        </div>
      )}

      {formValues.method === "forgot-password-email" && (
        <div className="flex flex-col gap-4 p-4">
          <h2>
            <strong>{intl.CREATE_NEW_PASSWORD}</strong>
          </h2>
          <p className="text-sm">{intl.FORGOTTEN_PASSWORD_INFO}</p>

          <Input
            id="forgot-password-email"
            {...register("email")}
            placeholder={intl.EMAIL}
            type="email"
          />

          <RecoveryButton
            isLoading={formState.isSubmitting}
            recoverPasswordTimestamp={recoverPasswordTimestamp}
            createTelephoneTokenTimestamp={createTelephoneTokenTimestamp}
          />

          <Button
            type="button"
            onClick={() => setValue("method", "email")}
            outlined
          >
            {intl.CANCEL}
          </Button>

          {isRecoverPasswordSuccess && (
            <Snackbar success showIcon={false}>
              {intl.RECOVER_PASSWORD_EMAIL_SENT}
            </Snackbar>
          )}
        </div>
      )}

      {formValues.method === "forgot-password-token" && (
        <div className="flex flex-col gap-4 p-4">
          <h1 className="text-2xl">
            <strong>{intl.CREATE_NEW_PASSWORD}</strong>
          </h1>
          <p>{intl.WRITE_YOUR_NEW_PASSWORD}</p>

          <input {...register("email")} type="hidden" />

          <Input
            id="new-password"
            {...register("newPassword")}
            placeholder={intl.NEW_PASSWORD}
            type="password"
          />

          <Input
            id="confirm-password"
            {...register("confirmPassword")}
            placeholder={intl.CONFIRM_PASSWORD}
            type="password"
          />

          <Button type="submit" loading={formState.isSubmitting} primary>
            {intl.SAVE_PASSWORD}
          </Button>
        </div>
      )}

      {isGeneralError && (
        <Snackbar id="login-error" error showIcon={false} className="mx-4 mb-4">
          {intl.GENERAL_ERROR}
        </Snackbar>
      )}
    </form>
  );
};

const RecoveryButton = ({
  isLoading,
  createTelephoneTokenTimestamp,
  recoverPasswordTimestamp,
}: {
  isLoading: boolean;
  createTelephoneTokenTimestamp?: number;
  recoverPasswordTimestamp?: number;
}) => {
  const [recoveryCooldown, setRecoveryCooldown] = useState(0);
  useEffect(() => {
    const timestamp = Math.max(
      recoverPasswordTimestamp || 0,
      createTelephoneTokenTimestamp || 0,
    );

    if (!timestamp) {
      return;
    }

    setRecoveryCooldown(RECOVERY_COOLDOWN);

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const diff = RECOVERY_COOLDOWN - (now - timestamp) / 1000;

      if (diff <= 0) {
        setRecoveryCooldown(0);
        clearInterval(interval);
        return;
      }

      setRecoveryCooldown(Math.ceil(diff));
    }, 1000);

    return () => clearInterval(interval);
  }, [recoverPasswordTimestamp, createTelephoneTokenTimestamp]);
  return (
    <Button primary loading={isLoading} disabled={recoveryCooldown > 0}>
      {recoveryCooldown <= 0
        ? intl.SEND_CODE
        : `${intl.SEND_CODE} (${recoveryCooldown})`}
    </Button>
  );
};
