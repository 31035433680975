import { useRouter } from "next/router";
import { GetStaticProps, InferGetStaticPropsType } from "next";

import { AppShell, NotFoundPage } from "@chef/feature-app-shell";
import {
  ContentComponent,
  ISection,
  SanityQuery,
  getSectionData,
  useSectionData,
} from "@chef/feature-sanity";

import { LoginForm } from "../components";
import { intl } from "./RecoverPasswordPage.Intl";

export const RecoverPasswordPage = ({
  sideContent: _sideContent,
}: InferGetStaticPropsType<typeof RecoverPasswordPageGetStaticProps>) => {
  const [sideContent] = useSectionData(_sideContent);

  const router = useRouter();
  const { token } = router.query;

  if (!token) {
    return <NotFoundPage />;
  }

  return (
    <AppShell title={intl.CREATE_NEW}>
      <AppShell.Meta />
      <AppShell.Header />
      <AppShell.Main grid>
        <div className="flex flex-col col-span-4 gap-6">
          <LoginForm recovery />
        </div>
        <div className="relative hidden h-screen col-span-4 overflow-y-hidden md:block xl:col-span-8">
          {sideContent && <ContentComponent block={sideContent.content} />}
        </div>
      </AppShell.Main>
      <AppShell.Footer />
    </AppShell>
  );
};

export const RecoverPasswordPageGetStaticProps: GetStaticProps<
  {
    draftMode: boolean;
    sideContent: ISection;
  },
  SanityQuery
> = async ({ draftMode = false }) => {
  const sideContent = await getSectionData("login_dialog", {
    preview: draftMode,
  });

  return {
    props: {
      draftMode,
      sideContent,
    },
    revalidate: 600,
  };
};
